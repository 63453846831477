import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./voyage-ui/building-blocks/voyage-ui.css";
import "./voyage-ui/building-blocks/voyage-ui-media-queries.css";
import HomeLoader from "./Pages/Home/HomeLoader";
import UnchainedLoader from "./Pages/Releases/Unchained/UnchainedLoader";
import RenaissanceLoader from "./Pages/Releases/Renaissance/RenaissanceLoader";
import PhoenixLoader from "./Pages/Releases/Phoenix/PhoenixLoader";
import PathfinderLoader from "./Pages/Releases/Pathfinder/PathfinderLoader";
import EmberLoader from "./Pages/Releases/Ember/EmberLoader";
import ForgeLoader from "./Pages/Releases/Forge/ForgeLoader";
import GauntletLoader from "./Pages/Releases/Gauntlet/GauntletLoader";
import SteamHomeLoader from "./Pages/Home/SteamHomeLoader";
import NavBar from "./components/NavBar";
import Releases from "./Pages/Releases/Releases";

ReactDOM.render(
  <>
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="*" element={<HomeLoader />} />
        <Route path="/" element={<HomeLoader />} />
        <Route path="/updates" element={<Releases />} />
        <Route path="/gauntlet" element={<GauntletLoader />} />
        <Route path="/forge" element={<ForgeLoader />} />
        <Route path="/unchained" element={<UnchainedLoader />} />
        <Route path="/phoenix" element={<PhoenixLoader />} />
        <Route path="/renaissance" element={<RenaissanceLoader />} />
        <Route path="/pathfinder" element={<PathfinderLoader />} />
        <Route path="/ember" element={<EmberLoader />} />
        <Route path="/steamapp" element={<SteamHomeLoader />} />
      </Routes>
    </BrowserRouter>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
